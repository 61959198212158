import(/* webpackMode: "eager", webpackExports: ["SecureCookiesProvider"] */ "/app/node_modules/next-client-cookies/dist/provider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Nunito_Sans\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"nunitoSans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\"],\"variable\":\"--font-poppins\"}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/predoc-sdk/dist/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["PHProvider"] */ "/app/src/context/posthog-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BeforeRouter"] */ "/app/src/Providers/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/reset.scss");
;
import(/* webpackMode: "eager" */ "/app/src/styles/global.css");
